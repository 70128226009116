const TestimonialsSection: React.FC = () => {
    const testimonials = [
      { name: "田中 太郎", feedback: "この取引所は信頼でき、取引手数料も安いので愛用しています。" },
      { name: "佐藤 花子", feedback: "使いやすいインターフェースで、初心者でも簡単に仮想通貨を購入できました。" },
    ];
  
    return (
      <section id="testimonials" className="py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">お客様の声</h2>
          <div className="flex justify-around">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-1/3 p-4">
                <p>"{testimonial.feedback}"</p>
                <p className="mt-2 text-sm text-gray-500">- {testimonial.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
  
  export default TestimonialsSection;
  