const CTASection: React.FC = () => {
    return (
      <section id="cta" className="py-12 bg-blue-600 text-white text-center">
        <h2 className="text-3xl font-bold mb-4">今すぐ仮想通貨取引を始めましょう！</h2>
        <button className="bg-white text-blue-600 px-6 py-3 rounded-full">無料登録はこちら</button>
      </section>
    );
  }
  
  export default CTASection;
  