const FeaturesSection: React.FC = () => {
    const features = [
      { title: "低手数料", description: "取引手数料が最も低い取引所" },
      { title: "安全な取引", description: "最先端のセキュリティ対策" },
      { title: "豊富な通貨", description: "幅広い仮想通貨を取引可能" },
    ];
  
    return (
      <section id="features" className="py-12 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">特徴</h2>
          <div className="flex justify-around">
            {features.map((feature, index) => (
              <div key={index} className="w-1/3 p-4">
                <h3 className="text-xl font-semibold">{feature.title}</h3>
                <p className="mt-2">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
  
  export default FeaturesSection;