const HeroSection: React.FC = () => {
    return (
      <section className="h-screen bg-hero-pattern bg-cover bg-center flex flex-col justify-center items-center">
        <h1 className="text-5xl font-bold text-white">仮想通貨取引を始めよう</h1>
        <p className="mt-4 text-lg text-white">信頼とセキュリティで選ばれている仮想通貨取引所</p>
        <button className="mt-6 bg-blue-600 text-white px-6 py-3 rounded-full">今すぐ無料登録</button>
      </section>
    );
  }
  
  export default HeroSection;