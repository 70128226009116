import React from "react";

const Navbar: React.FC = () => {
    return (
        <nav className="flex justify-between item-center p-6 bg-white shadow-md">
            <div className="text-2xl font-bold" >CryptoExchange</div>
            <div className="space-x-6">
                <a href="#features" className="text-gray-700">特徴</a>
                <a href="#testimonials" className="text-gray-700">お客様の声</a>
                <a href="#cta" className="text-white bg-blue-500 px-4 py-2 rounded">今すぐ登録</a>
            </div>
        </nav>
    );
}

export default Navbar;